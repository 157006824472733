/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

const TeamSection = () => {
  return (
    <section className="i pg ji gp uq">
      {/* Bg Shapes */}
      <span className="rc h s r vd fd/5 fh rm"></span>
      <img src="images/shape-08.svg" alt="Shape Bg" className="h q r" />
      <img src="images/shape-09.svg" alt="Shape" className="of h y z/2" />
      <img src="images/shape-10.svg" alt="Shape" className="h _ aa" />
      <img src="images/shape-11.svg" alt="Shape" className="of h m ba" />

      {/* Section Title Start */}
      <div x-data="{ sectionTitle: `Meet With Our Creative Dedicated Team`, sectionTitleText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam ante in maximus.`}">
        <div className="animate_top bb ze rj ki xn vq">
          <h2
            x-text="sectionTitle"
            className="fk vj pr kk wm on/5 gq/2 bb _b"
          ></h2>
          <p className="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
        </div>
      </div>
      {/* Section Title End */}

      <div className="bb ze i va ki xn xq jb jo">
        <div className="wc qf pn xo gg cp">
          {/* Team Item */}
          <div className="animate_top rj">
            <div className="c i pg z-1">
              <img className="vd" src="images/David.jpg" alt="Team" />

              <div className="ef im nl il">
                <span className="h -ud-left-5 -ud-bottom-21 rc de gd gh if wa"></span>
                <span className="h s p rc vd hd mh va"></span>
                <div className="h s p vd ij jj xa">
                  <ul className="tc xf wf gg">
                    <li>
                      <a href="https://www.linkedin.com/in/ubong-mathew-a07a3111b/">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1152_302)">
                            <path
                              d="M18.125 0.468822H1.875C1.5063 0.46507 1.15117 0.607735 0.887529 0.865514C0.623892 1.12329 0.473283 1.47513 0.46875 1.84382V18.1594C0.474103 18.5276 0.625072 18.8786 0.88862 19.1357C1.15217 19.3928 1.50683 19.5351 1.875 19.5313H18.125C18.4937 19.5342 18.8486 19.3911 19.1121 19.1332C19.3756 18.8752 19.5263 18.5235 19.5312 18.1548V1.83913C19.5247 1.47152 19.3733 1.12136 19.1099 0.864769C18.8466 0.608173 18.4927 0.465881 18.125 0.468822Z"
                              fill="#0076B2"
                            />
                            <path
                              d="M3.29043 7.61719H6.12012V16.7219H3.29043V7.61719ZM4.70606 3.08594C5.03061 3.08594 5.34787 3.1822 5.6177 3.36254C5.88753 3.54288 6.09782 3.79921 6.22194 4.09908C6.34607 4.39896 6.37847 4.72891 6.31504 5.04721C6.25161 5.3655 6.0952 5.65783 5.8656 5.88721C5.636 6.1166 5.34352 6.27273 5.02517 6.33585C4.70681 6.39898 4.37689 6.36627 4.07713 6.24185C3.77737 6.11744 3.52125 5.90691 3.34117 5.63691C3.16108 5.3669 3.06512 5.04955 3.06543 4.725C3.06584 4.29015 3.23888 3.87325 3.54651 3.56591C3.85414 3.25857 4.27121 3.08594 4.70606 3.08594ZM7.89512 7.61719H10.6076V8.86719H10.6451C11.0232 8.15156 11.9451 7.39688 13.3217 7.39688C16.1873 7.39063 16.7186 9.27656 16.7186 11.7219V16.7219H13.8889V12.2922C13.8889 11.2375 13.8701 9.87969 12.4186 9.87969C10.967 9.87969 10.7201 11.0297 10.7201 12.2234V16.7219H7.89512V7.61719Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1152_302">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    {/* ... Other social media icons ... */}
                  </ul>
                </div>
              </div>
            </div>

            <h4 className="yj go kk wm ob zb">UBONG MATHEW</h4>
            <p>Business/Management</p>
          </div>

          <div className="animate_top rj">
            <div className="c i pg z-1">
              <img className="vd" src="images/Godfred.jpg" alt="Team" />

              <div className="ef im nl il">
                <span className="h -ud-left-5 -ud-bottom-21 rc de gd gh if wa"></span>
                <span className="h s p rc vd hd mh va"></span>
                <div className="h s p vd ij jj xa">
                  <ul className="tc xf wf gg">
                    <li>
                      <a href="https://www.linkedin.com/in/godfredakpan/">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1152_302)">
                            <path
                              d="M18.125 0.468822H1.875C1.5063 0.46507 1.15117 0.607735 0.887529 0.865514C0.623892 1.12329 0.473283 1.47513 0.46875 1.84382V18.1594C0.474103 18.5276 0.625072 18.8786 0.88862 19.1357C1.15217 19.3928 1.50683 19.5351 1.875 19.5313H18.125C18.4937 19.5342 18.8486 19.3911 19.1121 19.1332C19.3756 18.8752 19.5263 18.5235 19.5312 18.1548V1.83913C19.5247 1.47152 19.3733 1.12136 19.1099 0.864769C18.8466 0.608173 18.4927 0.465881 18.125 0.468822Z"
                              fill="#0076B2"
                            />
                            <path
                              d="M3.29043 7.61719H6.12012V16.7219H3.29043V7.61719ZM4.70606 3.08594C5.03061 3.08594 5.34787 3.1822 5.6177 3.36254C5.88753 3.54288 6.09782 3.79921 6.22194 4.09908C6.34607 4.39896 6.37847 4.72891 6.31504 5.04721C6.25161 5.3655 6.0952 5.65783 5.8656 5.88721C5.636 6.1166 5.34352 6.27273 5.02517 6.33585C4.70681 6.39898 4.37689 6.36627 4.07713 6.24185C3.77737 6.11744 3.52125 5.90691 3.34117 5.63691C3.16108 5.3669 3.06512 5.04955 3.06543 4.725C3.06584 4.29015 3.23888 3.87325 3.54651 3.56591C3.85414 3.25857 4.27121 3.08594 4.70606 3.08594ZM7.89512 7.61719H10.6076V8.86719H10.6451C11.0232 8.15156 11.9451 7.39688 13.3217 7.39688C16.1873 7.39063 16.7186 9.27656 16.7186 11.7219V16.7219H13.8889V12.2922C13.8889 11.2375 13.8701 9.87969 12.4186 9.87969C10.967 9.87969 10.7201 11.0297 10.7201 12.2234V16.7219H7.89512V7.61719Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1152_302">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    {/* ... Other social media icons ... */}
                  </ul>
                </div>
              </div>
            </div>

            <h4 className="yj go kk wm ob zb">Godfred Akpan</h4>
            <p>Technical/Management</p>
          </div>
          <div className="animate_top rj">
            <div className="c i pg z-1">
              <img className="vd" src="images/Abas.jpg" alt="Team" />

              <div className="ef im nl il">
                <span className="h -ud-left-5 -ud-bottom-21 rc de gd gh if wa"></span>
                <span className="h s p rc vd hd mh va"></span>
                <div className="h s p vd ij jj xa">
                  <ul className="tc xf wf gg">
                    <li>
                      <a href="https://www.linkedin.com/in/abasiodiong-eton-693970164/">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1152_302)">
                            <path
                              d="M18.125 0.468822H1.875C1.5063 0.46507 1.15117 0.607735 0.887529 0.865514C0.623892 1.12329 0.473283 1.47513 0.46875 1.84382V18.1594C0.474103 18.5276 0.625072 18.8786 0.88862 19.1357C1.15217 19.3928 1.50683 19.5351 1.875 19.5313H18.125C18.4937 19.5342 18.8486 19.3911 19.1121 19.1332C19.3756 18.8752 19.5263 18.5235 19.5312 18.1548V1.83913C19.5247 1.47152 19.3733 1.12136 19.1099 0.864769C18.8466 0.608173 18.4927 0.465881 18.125 0.468822Z"
                              fill="#0076B2"
                            />
                            <path
                              d="M3.29043 7.61719H6.12012V16.7219H3.29043V7.61719ZM4.70606 3.08594C5.03061 3.08594 5.34787 3.1822 5.6177 3.36254C5.88753 3.54288 6.09782 3.79921 6.22194 4.09908C6.34607 4.39896 6.37847 4.72891 6.31504 5.04721C6.25161 5.3655 6.0952 5.65783 5.8656 5.88721C5.636 6.1166 5.34352 6.27273 5.02517 6.33585C4.70681 6.39898 4.37689 6.36627 4.07713 6.24185C3.77737 6.11744 3.52125 5.90691 3.34117 5.63691C3.16108 5.3669 3.06512 5.04955 3.06543 4.725C3.06584 4.29015 3.23888 3.87325 3.54651 3.56591C3.85414 3.25857 4.27121 3.08594 4.70606 3.08594ZM7.89512 7.61719H10.6076V8.86719H10.6451C11.0232 8.15156 11.9451 7.39688 13.3217 7.39688C16.1873 7.39063 16.7186 9.27656 16.7186 11.7219V16.7219H13.8889V12.2922C13.8889 11.2375 13.8701 9.87969 12.4186 9.87969C10.967 9.87969 10.7201 11.0297 10.7201 12.2234V16.7219H7.89512V7.61719Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1152_302">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    {/* ... Other social media icons ... */}
                  </ul>
                </div>
              </div>
            </div>

            <h4 className="yj go kk wm ob zb">Abasiodiong Eton</h4>
            <p>Creative/Management</p>
          </div>

          {/* Repeat the above structure for other team members */}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
