// Import necessary modules
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import BlogPage from './pages/BlogPage';
import ContactPage from './pages/ContactPage';
import BlogDetails from './components/BlogDetails';
import NotFoundPage from './components/NotFound' // Make sure to have this component

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/services" component={ServicesPage} />
        {/* <Route path="/blog" component={BlogPage} /> */}
        <Route path="/blogDetails" component={BlogDetails} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/404" component={NotFoundPage} />
        {/* Redirect to 404 page if no matching route is found */}
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;
