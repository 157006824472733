/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Hero = () => {
  return (
    <section className="gj do ir hj sp jr i pg">
      {/* Hero Images */}
      <div className="xc fn zd/2 2xl:ud-w-187.5 bd 2xl:ud-h-171.5 h q r">
        <img
          src="images/shape-01.svg"
          alt="shape"
          className="xc 2xl:ud-block h t -ud-left-[10%] ua"
        />
        <img
          src="images/shape-02.svg"
          alt="shape"
          className="xc 2xl:ud-block h u p va"
        />
        <img
          src="images/shape-03.svg"
          alt="shape"
          className="xc 2xl:ud-block h v w va"
        />
        <img src="images/shape-04.svg" alt="shape" className="h q r" />
        <img src="images/hero.png" alt="Woman" className="h q r ua" />
      </div>

      {/* Hero Content */}
      <div className="bb ze ki xn 2xl:ud-px-0">
        <div className="tc _o">
          <div className="animate_left jn/2">
            <h1 className="fk vj zp or kk wm wb">
              We deliver simple software solutions for your business.
            </h1>
            <p className="fq">
              Many companies and organizations need smart solutions. In
              response, we've created helpful solutions to assist with time
              management, resource management, productivity improvement,
              competency recognition, and enabling employees to work
              efficiently.
            </p>

            <div className="tc tf yo zf mb">
              <a
                style={{ backgroundColor: "black" }}
                href="/contact"
                className="ek jk lk gh gi hi rg ml il vc _d _l"
              >
                Get Started Now
              </a>

              <span className="tc sf">
                <a href="/contact" className="inline-block ek xj kk wm">
                  {" "}
                  Contact Us{" "}
                </a>
                <span className="inline-block">
                  For any question or concern
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
