import React, { useEffect, useState } from 'react';
import Footer from './layouts/Footer';
import Header from './layouts/Header';

const BlogDetails = () => {
  const [blogData, setBlogData] = useState(null);

  const fetchDataFromLocalStorage = () => {
    const storedData = localStorage.getItem('blogDetails');

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setBlogData(parsedData);
    }
  };

  useEffect(() => {
    fetchDataFromLocalStorage();
  }, []);

  return (
    <div>
      <Header page="blogDetails" />
      <main>
    <section className="gj qp gr hj rp hr">
          {blogData && (
            <div className="bb ze ki xn 2xl:ud-px-0">
              <div className="tc sf yo zf kq">
                <div className="ro">
                  <div className="animate_top rounded-md shadow-solid-13 bg-white dark:bg-blacksection border border-stroke dark:border-strokedark p-7.5 md:p-10">
                    <img src={blogData.image} alt="Blog" />

                    <h2 className="ek vj 2xl:ud-text-title-lg kk wm nb gb">{blogData.title}</h2>

                    <ul className="tc uf cg 2xl:ud-gap-15 fb">
                      <li><span className="rc kk wm">Author: </span>{blogData.author}</li>
                      <li><span className="rc kk wm">Published On: </span>{blogData.publishedDate}</li>
                      <li><span className="rc kk wm">Category: </span>{blogData.category}</li>
                    </ul>

                    <p>{blogData.content}</p>

                    <div className="wc qf pn dg cb">
                      <img src={blogData.image1} alt="Blog" />
                      <img src={blogData.image2} alt="Blog" />
                    </div>

                    <h2 className="ek vj 2xl:ud-text-title-lg kk wm nb qb">The powerful force of humanity</h2>
                    <p>{blogData.content2}</p>

                    <ul className="tc wf bg sb">
                      <li>
                        <p className="sj kk wm tb">Share On:</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        </main>
      <Footer />
    </div>
  );
};

export default BlogDetails;
