import React from 'react';

const SmallFeatures = () => {
  return (
    <section id="features">
      <div className="bb ze ki yn 2xl:ud-px-12.5">
        <div className="tc uf zo xf ap zf bp mq">
          {/* Small Features Item 1 */}
          <div  className="animate_top kn to/4 tc cg oq">
            <div style={{backgroundColor: '#F6CA1D'}} className="tc wf xf cf ae cd rg mh">
              <img src="images/icon-01.svg" alt="Icon" />
            </div>
            <div>
              <h6 className="ek yj go kk wm xb">Confident</h6>
              <p>We deliver your services with confidence.</p>
            </div>
          </div>

          {/* Small Features Item 2 */}
          <div className="animate_top kn to/4 tc cg oq">
            <div style={{backgroundColor: '#F43760'}}  className="tc wf xf cf ae cd rg nh">
              <img src="images/icon-04.svg" alt="Icon" />
            </div>
            <div>
              <h6 className="ek yj go kk wm xb">Fast</h6>
              <p>We not only deliver fast, but also deliver efficiently.</p>
            </div>
          </div>

          {/* Small Features Item 3 */}
          <div className="animate_top kn to/4 tc cg oq">
            <div style={{backgroundColor: '#19C0A2'}} className="tc wf xf cf ae cd rg oh">
              <img src="images/icon-05.svg" alt="Icon" />
            </div>
            <div>
              <h6 className="ek yj go kk wm xb">Team Work</h6>
              <p>We work hand in hand with you to deliver what you need</p>
            </div>
          </div>

          {/* Small Features Item 4 */}
          <div className="animate_top kn to/4 tc cg oq">
            <div style={{backgroundColor: '#811AF0'}} className="tc wf xf cf ae cd rg oh">
              <img src="images/icon-06.svg" alt="Icon" />
            </div>
            <div>
              <h6 className="ek yj go kk wm xb">Secure</h6>
              <p>We make sure that our services are secured to industry standard.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmallFeatures;
