import React from "react";

const AboutSection = () => {
  return (
    <section className="ji gp uq 2xl:ud-py-35 pg">
      <div className="bb ze ki xn wq">
        <div className="tc wf gg qq">
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              <img
                // width="1200px"
                src="images/about2.png"
                alt="About"
                className="ib"
              />
              {/* <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              /> */}
              <img src="images/shape-06.svg" alt="Shape" />
              {/* <img src="images/about-02.png" alt="About" /> */}
            </div>
            <div>
              {/* <img src="images/about-03.png" alt="About" className="ob gb" /> */}
              <img src="images/shape-07.svg" alt="Shape" className="bb" />
            </div>
          </div>

          {/* About Content */}
          <div className="animate_right jn/2">
            <h4 className="ek yj mk gb">A Team</h4>
            <h2 className="fk vj zp pr kk wm qb">Who are we?</h2>
            <p className="uo">
              We are this fascinating team of technocrats whose desire and
              determination is to provide a highly computerized platform which
              your company can rely on to give efficiency and proficiency to
              work done. We aim to eliminate work stress, increase work
              productivity, simplify work interactions, save work time and
              ultimately meet work targets. 
              We are at your service...and ready
              to serve you.
            </p>

            <a
              href="/contact"
              data-fslightbox
              className="vc wf hg mb"
            >
              <span className="tc wf xf be dd rg i gh ua">
                <span className="nf h vc yc vd rg gh qk -ud-z-1"></span>
                {/* <img src="images/icon-play.svg" alt="Play" /> */}
              </span>
              <span className="kk">Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
