import React from 'react';

const NotFoundPage = () => {
  return (
    <main >
      <section className="i pg fh rm ej np er fj op fr">
        {/* Bg Shapes */}
        <img src="images/shape-06.svg" alt="Shape" className="h j k" />
        <img src="images/shape-03.svg" alt="Shape" className="h l m" />
        <img src="images/shape-17.svg" alt="Shape" className="h n o" />
        <img src="images/shape-18.svg" alt="Shape" className="h p q" />

        <div className="animate_top bb xe rj">
          <img src="images/loki.svg" alt="404" style={{height: 300}} className="bb fb" />

          <h2 className="ek tj eo kk wm gb">The page has been pruned!</h2>
          <p className="hb">The page you were looking for appears to been in another timeline. </p>

          <a href="/" className="ek vc rg gh lk ml il _l gi hi" style={{
                backgroundColor: "#2A5817",
                color: "white",
                borderColor: "black",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}>
            Back to Home
          </a>
        </div>
      </section>
      {/* ===== 404 End ===== */}
    </main>
  );
};

export default NotFoundPage;
