/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";

const Header = ({ page }) => {
  const [stickyMenu, setStickyMenu] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setStickyMenu(window.pageYOffset > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`g s r vd ya cj ${stickyMenu ? "hh sm _k dj bl ll" : ""}`}
    >
      <div className="bb ze ki xn 2xl:ud-px-0 oo wf yf i">
        <div className="vd to/4 tc wf yf">
          <a href="/">
            <img
              className="om"
              src="images/logo.png"
              width={150}
              alt="Logo Light"
            />
            {/* <img className="xc nm" src="images/logo.png" alt="Logo Dark" /> */}
          </a>
          <button
            className="po rc"
            onClick={() => setNavigationOpen(!navigationOpen)}
          >
            <span className="rc i pf re pd">
              <span className="du-block h q vd yc">
                <span
                  className={`rc i r s eh um tg te rd eb ml jl dl ${
                    !navigationOpen ? "ue el" : ""
                  }`}
                ></span>
                <span
                  className={`rc i r s eh um tg te rd eb ml jl fl ${
                    !navigationOpen ? "ue qr" : ""
                  }`}
                ></span>
                <span
                  className={`rc i r s eh um tg te rd eb ml jl gl ${
                    !navigationOpen ? "ue hl" : ""
                  }`}
                ></span>
              </span>
              <span className="du-block h q vd yc lf">
                <span
                  className={`rc eh um tg ml jl el h na r ve yc ${
                    !navigationOpen ? "sd dl" : ""
                  }`}
                ></span>
                <span
                  className={`rc eh um tg ml jl qr h s pa vd rd ${
                    !navigationOpen ? "sd rr" : ""
                  }`}
                ></span>
              </span>
            </span>
          </button>
        </div>

        <div
          className={`vd wo/4 sd qo f ho oo wf yf ${
            navigationOpen ? "d hh rm sr td ud qg ug jc yh" : ""
          }`}
        >
          <nav>
            <ul className="tc _o sf yo cg ep">
              <li>
                <a
                  href="/"
                  className="xl"
                  style={{ color: page === "home" ? "#811AF0" : "black" }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="xl"
                  style={{ color: page === "services" ? "#811AF0" : "black" }}
                >
                  Services
                </a>
              </li>
              {/* <li>
                <a
                  href="/blog"
                  className="xl"
                  style={{ color: page === "blog" ? "#811AF0" : "black" }}
                >
                  Blog
                </a>
              </li> */}
              <li>
                <a
                  href="/about"
                  className="xl"
                  style={{ color: page === "about" ? "#811AF0" : "black" }}
                >
                  About Us
                </a>
              </li>
            </ul>
          </nav>

          <div className="hidden lg:block tc wf ig pb no">
            <div className="tc wf ig pb no">
              <div
                className={`pc h io pa ra ${
                  navigationOpen ? "!-ud-visible" : "d"
                } hidden-on-mobile`}
              >
                <a
                  style={{ backgroundColor: "black" }}
                  href="/contact"
                  className="ek jk lk gh gi hi rg ml il vc _d _l"
                >
                  Get Started Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
