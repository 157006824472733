/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const About = () => {
  return (
    <section
      className="ji gp uq 2xl:ud-py-35 pg"
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <div className="bb ze ki xn wq">
        <div className="tc wf gg qq">
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              />
              <img src="images/about-1.png" alt="About" className="ib" />
              <img src="images/about-3.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-06.svg" alt="Shape" />
              <img src="images/about-2.png" alt="About" className="ob gb" />
              <img src="images/shape-07.svg" alt="Shape" className="bb" />
            </div>
          </div>

          {/* About Content */}
          <div className="animate_right jn/2">
            <h4 className="ek yj mk gb">Our Mission</h4>
            <h2 className="fk vj zp pr kk wm qb">
              Our center of interest is to help your company achieve
              digitalization at it's simpliest form.
            </h2>
            <p className="uo">
              - We provide you a quality interactive work platform that saves
              working time and effort, enhancing productivity.
            </p>
            <p className="uo">
              - A simple, fast, smart and competent software application that
              prolifically meets your work needs and demands.
            </p>

            <a
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: "black",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}
              href="/contact"
              className="ek jk lk gh gi hi rg ml il vc _d _l"
            >
              Get Started Now
            </a>
          </div>
        </div>

        <div
          className="tc wf gg qq"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {/* About Content */}
          <div className="animate_right jn/2">
            <h4 className="ek yj mk gb">Our Value</h4>
            <div style={{ marginBottom: "15px" }}>
              <h2 className="fk vj zp pr kk wm qb">
                Versatility and Flexibility.
              </h2>
              <p className="uo">
                Moreso, a fully secured software that gives best user
                performance and which is versatile and flexible to all
                categories of business and nature of the company.
              </p>
            </div>
            <div>
              <h2 className="fk vj zp pr kk wm qb">
                Enjoyable User Experience
              </h2>
              <p className="uo">
                We pride in building and maintaining tools that will definitely
                make work easier and faster for staff.
              </p>
            </div>

            <a
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: "black",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}
              href="/contact"
              className="ek jk lk gh gi hi rg ml il vc _d _l"
            >
              Get Started Now
            </a>
          </div>
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              />
              <img src="images/about-4.png" alt="About" className="ib" />
              <img src="images/about-5.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-06.svg" alt="Shape" />
              <img src="images/about-6.png" alt="About" className="ob gb" />
              <img src="images/shape-07.svg" alt="Shape" className="bb" />
            </div>
          </div>
        </div>

        <div className="tc wf gg qq">
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              />
              <img src="images/about-7.png" alt="About" className="ib" />
              <img src="images/about-9.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-06.svg" alt="Shape" />
              <img src="images/about-8.png" alt="About" className="ob gb" />
              <img src="images/shape-07.svg" alt="Shape" className="bb" />
            </div>
          </div>

          {/* About Content */}
          <div className="animate_right jn/2">
            <h4 className="ek yj mk gb">Why Choose Us</h4>
            <h2 className="fk vj zp pr kk wm qb">The Essence.</h2>
            <p className="uo">
              In the world that technology is obviously the future, we present
              you an automation system that speedily streamlines work processes
              and effectively manage your company time, data and other
              resources.
            </p>

            <a
              href="/contact"
              data-fslightbox
              className="vc wf hg mb"
            >
              <span className="tc wf xf be dd rg i gh ua">
                <span className="nf h vc yc vd rg gh qk -ud-z-1"></span>
              </span>
              <span className="kk">Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
