// src/components/Home.js
import React from "react";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Hero from "./elements/Home/Hero";
import SmallFeatures from "./elements/Home/SmallFeatures";
import About from "./elements/Home/About";
import Clients from "./elements/Home/Clients";
import CTA from "./elements/Home/CTA";

const Home = () => {
  return (
    <div>
      <Header page="home" />
      <Hero />
      <SmallFeatures />
      <About />
      {/* <Clients/> */}
      <CTA />
      <Footer />
    </div>
  );
};

export default Home;
