// src/components/Home.js
import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import AboutSection from './elements/About/AboutSection';
import TeamSection from './elements/About/TeamSection';
import CTA from './elements/Home/CTA';

const About = () => {
  return (
    <div>
     <Header page="about"/>
     <AboutSection/>
     <TeamSection/>
     <CTA/>
     <Footer/>
    </div>
  );
};

export default About;
