// src/components/Home.js
import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import ServicesSection from './elements/Services/ServicesSection';
import CTA from './elements/Home/CTA';

const Services = () => {
  return (
    <div>
       <Header page="services"/>
       <ServicesSection/>
       <CTA/>
       <Footer/>
    </div>
  );
};

export default Services;
