/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CTA = () => {
  return (
    <section className="i pg gh ji" style={{ backgroundColor: "black" }}>
      <img className="h p q" src="images/shape-16.svg" alt="Bg Shape" />

      <div className="bb ye i z-10 ki xn dr">
        <div className="tc uf sn tn un gg">
          <div className="animate_left to/2">
            <h2 className="fk vj zp pr lk ac">Let's work together</h2>
            {/* <p className="lk">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis nibh lorem. Duis sed odio lorem. In a efficitur leo. Ut venenatis rhoncus.
            </p> */}
          </div>
          <div className="animate_right bf">
            <a href="/contact" className="vc ek kk hh rg ol il cm gi hi">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
